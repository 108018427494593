import { ContractObligationVersions, LicenseAgreementVersions } from './interfaces/TermsAndConditions.types';

export const CURRENT_CONTRACT_OBLIGATION_VERSION = ContractObligationVersions.V1;
export const CURRENT_LICENSE_AGREEMENT_VERSION: string = LicenseAgreementVersions.V0;

export const CONTRACT_OBLIGATION_PREFIX = 'CONTRACT_OBLIGATION_';
export const LICENSE_AGREEMENT_PREFIX = 'LICENSE_AGREEMENT_';

// eslint-disable-next-line max-len
export const CURRENT_TERMS_AND_CONDITIONS_GROUP = `${LICENSE_AGREEMENT_PREFIX}${CURRENT_LICENSE_AGREEMENT_VERSION}+${CONTRACT_OBLIGATION_PREFIX}${CURRENT_CONTRACT_OBLIGATION_VERSION}`;
