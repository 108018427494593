import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledContractObligationModal = styled(Modal)`
  ${(props): string => props.theme.fontStyles.p1.news};

  .ant-modal-body {
    height: 70vh;
    overflow: auto;
    margin-bottom: 16px;
    padding: 0 24px;
  }

  .ant-modal-footer {
    flex-direction: row;
    justify-content: space-between;
  }

  .contract-obligation-modal__download-button {
    text-transform: uppercase;
  }
`;
