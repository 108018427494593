import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LegacyPrimaryButton } from '../../../../../../lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { selectUserCountryCode } from '../../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Translations } from '../../../../../cross-cutting-concerns/translations/Translations';
import { ContractObligationContent } from '../../../components/ContractObligationContent/ContractObligationContent';
import { TermsConditionsUtils } from '../../../utils/TermsConditionsUtils';
import * as termsConditionsModalsSelectors from '../../state/termsConditionsModalsSelectors';
import { TermsConditionsModalsActions } from '../../state/termsConditionsModalsActions';
import { StyledContractObligationModal } from './ContractObligationModal.styles';
import { LegacySecondaryButton } from 'lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';

export const ContractObligationModal = (): React.JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(termsConditionsModalsSelectors.selectIsContractObligationModalVisible);
  const userCountryCode = useSelector(selectUserCountryCode);
  const country = Translations.getCountryByCountryCode(userCountryCode);
  const filePath = TermsConditionsUtils.getContractObligationFilePathByCountry(country);
  const isDownloadButtonVisible = filePath.length > 0;

  const handleCancel = useCallback(() => {
    dispatch(TermsConditionsModalsActions.hideContractObligationModal());
  }, [dispatch]);

  const buttons = [
    <LegacySecondaryButton
      size="m"
      onClick={handleCancel}
      className="contract-obligation-modal__cancel-button"
      key="cancel-button"
    >
      {t('common.back')}
    </LegacySecondaryButton>,
  ];

  if (isDownloadButtonVisible) {
    buttons.push(
      <LegacyPrimaryButton className="contract-obligation-modal__download-button" size="m" href={filePath} download>
        {t('common.download')}
      </LegacyPrimaryButton>
    );
  }

  return (
    <StyledContractObligationModal
      className="contract-obligation-modal"
      title={t('common.contractObligation')}
      open={isVisible}
      footer={buttons}
    >
      <ContractObligationContent />
    </StyledContractObligationModal>
  );
};
