/* eslint-disable max-len */

import { LicenseAgreementVersions } from '../../../../interfaces/TermsAndConditions.types';

export const licenseAgreementContentSwitzerland: Record<LicenseAgreementVersions, string> = {
  [LicenseAgreementVersions.V0]: `<div></div>`,
  [LicenseAgreementVersions.V1]: `
    <div>
      <div class="license-agreement-content__heading">
        <div class="license-agreement-content__text">Allgemeine Vertragsbedingungen für die Nutzung des Kärcher
          Equipment
          Management Kundenportals (AVB)
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">1.</div>
        <div class="license-agreement-content__text">Allgemeines</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">1.1</div>
        <div class="license-agreement-content__text">Diese Allgemeinen Vertragsbedingungen (nachfolgend:
          „Vertragsbedingungen" oder „AVB") der [ _____________ ] (nachfolgend auch: „Kärcher") gelten nur gegenüber
          Unternehmern, juristischen Personen des privaten und öffentlichen Rechts oder öffentlich‐rechtlichen
          Sondervermögen im Sinne des § 310 Abs. 1 BGB (nachfolgend: „Kunde").
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">1.2</div>
        <div class="license-agreement-content__text">Wenn Sie sich im Kundenportal von Kärcher erstmalig anmelden und
          diese AVB im Namen eines Kunden akzeptieren, sichern Sie Kärcher hiermit zu, dass Sie befugt sind, den Kunden an
          diese AVB zu binden. Wenn Sie nicht über eine solche Befugnis verfügen oder wenn Sie oder der Kunde nicht zustimmen,
          an diese AVB gebunden zu sein, dürfen Sie diese AVB nicht akzeptieren und Sie sowie der Kunde dürfen die
          vertragsgegenständlichen Leistungen nicht nutzen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">1.3</div>
        <div class="license-agreement-content__text">Allgemeine Geschäftsbedingungen des Kunden finden keine Anwendung.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">2.</div>
        <div class="license-agreement-content__text">Allgemeine Informationen zum Vertragsgegenstand</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">2.1</div>
        <div class="license-agreement-content__text">Kärcher bietet mit seinem Equipment Management System gewerblichen
          Kunden eine zugangsgesicherte webbasierte Softwareanwendung für die einfache Flotten- und Maschinenverwaltung
          in
          Form eines Kundenportals an.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">2.2</div>
        <div class="license-agreement-content__text">Die im Rahmen des Kundenportals im Einzelnen vom Kunden abrufbaren
          Daten und Funktionen hängen davon ab, welches Maschinenmodell der Kunde nutzt und ob bzw. welches
          Hardwaremodul
          für Datenübertragung in diesem verbaut ist. Eine Übersicht über die Funktionen und abrufbaren Daten des
          jeweiligen
          Hardwaremoduls können Sie der Leistungsbeschreibung für das Hardwaremodul entnehmen oder wird Ihnen auf
          Anfrage
          von Kärcher zur Verfügung gestellt.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">2.3</div>
        <div class="license-agreement-content__text">Stammdaten und Basisfunktionen des Kundenportals (nachfolgend
          „Equipment Management") stehen dem Kunden bereits ohne eingebautes Hardware-Modul für Datenübertragung sowohl
          für
          Maschinen von Kärcher wie auch Maschinen von Drittanbietern zur Verfügung.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">2.4</div>
        <div class="license-agreement-content__text">Für die Nutzung von sog. "Near-real-time"-Daten und
          Zusatzfunktionen
          über das Kundenportal muss die jeweilige Maschine des Kunden mit einem Hardware-Modul für Datenübertragung
          ausgerüstet sein oder nachgerüstet werden.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">2.5</div>
        <div class="license-agreement-content__text">Der Kunde kann in das Kundenportal weitere eigene kunden- und
          maschinenbezogene Daten einpflegen, wie beispielsweise die zu reinigenden Gebäude, die Reinigungszeiten oder
          sonstige Angaben zur Nutzung der jeweiligen Maschine (nachfolgend: „Portaldaten").
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">2.6</div>
        <div class="license-agreement-content__text">Kärcher gewährt dem Kunden Zugriff auf das Kundenportal und
          ermöglicht
          ihm die Nutzung.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">2.7</div>
        <div class="license-agreement-content__text">Der Kunde kann nach Einrichtung des Kundenkontos durch Kärcher über
          die
          URL https://em.kaercher.com/ auf das Kundenportal zugreifen. Unterstützt wird der Zugriff über die jeweils
          aktuellen Versionen der Internetbrowser Google Chrome, Microsoft Edge, und Mozilla Firefox, Apple Safari und
          des
          Betriebssystems Microsoft Windows. Der Zugriff über andere Internetbrowser und Betriebssysteme kann
          eingeschränkt
          sein. Ein Zugriff über mobile Endgeräte wird nicht unterstützt.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">3.</div>
        <div class="license-agreement-content__text">Vertragsschluss</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">3.1</div>
        <div class="license-agreement-content__text">Dem Kunden werden von Kärcher seine Zugangsdaten (z.B. User,
          Initialpasswort) sowie der Link zum Portal auf Anfrage zur Verfügung gestellt. Die Anfrage des Kunden kann
          formlos, z.B. per Telefon oder E-Mail, erfolgen. Mit den von Kärcher zur Verfügung gestellten Zugangsdaten
          kann
          sich der Kunde unter Zustimmung zu diesen Vertragsbedingungen im Portal anmelden. Der Vertrag zur Nutzung des
          Equipment Management Systems zwischen Kärcher und dem Kunden kommt mit dem Login unter Annahme dieser
          Vertragsbedingungen zustande. Der in der Anfrage des Kunden angegebene Ansprechpartner wird dabei auf dem
          Kundenkonto als Administrator hinterlegt und kann im Kundenkonto weitere Zugangsberechtigungen für Mitarbeiter
          des
          Kunden erstellen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">4.</div>
        <div class="license-agreement-content__text">Wartung und Verfügbarkeit</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">4.1</div>
        <div class="license-agreement-content__text">Die Überwachung der Erreichbarkeit des Kundenportals erfolgt
          montags
          bis freitags zwischen 9 und 16 Uhr MEZ (nachfolgend auch „Servicezeit")). Hiervon ausgenommen sind gesetzliche
          Feiertage in Deutschland (Baden-Württemberg). Bei schwerwiegenden Störungen - die Nutzung des Kundenportals
          ist
          nicht mehr möglich bzw. wesentlich eingeschränkt - erfolgt die Einleitung von Wartungsmaßnahmen innerhalb der
          Servicezeitunverzüglich nach der Kenntnis oder Information durch den Kunden. Kärcher wird den Kunden von den
          Wartungsarbeiten umgehend über das Kundenportal oder per E-Mail informieren und diese den technischen
          Bedingungen
          entsprechend in der möglichst kürzesten Zeit durchführen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">4.2</div>
        <div class="license-agreement-content__text">Die Verfügbarkeit des Kundenportals beträgt 98 % im
          Jahresdurchschnitt
          mit Ausnahme von Zeiträumen für Wartungsarbeiten.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">4.3</div>
        <div class="license-agreement-content__text">Der Anbieter ist berechtigt, die Leistungserbringung außerhalb der
          Servicezeit zu unterbrechen, um Wartungsarbeiten durchzuführen. Kärcher wird sich bemühen, den genauen
          Zeitraum
          der Wartungsarbeiten fünf Tage im Voraus dem Kunden über das Kundenportal oder per E-Mail mitzuteilen. Sollten
          Wartungsarbeiten oder Störungsbeseitigungen außerplanmäßig und/oder unvorhersehbar zu anderen Zeiten
          erforderlich
          werden und eine Einhaltung der fünftägigen Ankündigungsfrist für Kärcher nicht zumutbar möglich sein, wird
          Kärcher
          dem Kunden frühest möglich Zeitpunkt und Dauer der Leistungsunterbrechung mitteilen. Während geplanter,
          regelmäßiger Wartungsarbeiten („planmäßige Wartungsarbeiten") hat der Kunde keinen Anspruch auf die Nutzung
          der
          Softwareanwendung.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">4.4</div>
        <div class="license-agreement-content__text">Eine von Kärcher zu behebende Störung liegt nicht vor bei
          Beeinträchtigungen der Datenverbindung von den Endgeräten des Kunden und seiner Nutzer in das Internet, z.B.
          durch
          Leitungsausfall oder -störung bei anderen Providern oder Telekommunikationsanbietern, oder bei einer
          vertragswidrigen Inanspruchnahme der bereitgestellten Systemkapazitäten.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">4.5</div>
        <div class="license-agreement-content__text">Bei Verbindungsproblemen zwischen der Cloud-basierten
          Softwareanwendung
          und den Maschinen des Kunden kann es passieren, dass die Daten der jeweiligen Maschine nicht im Kundenportal
          verfügbar sind. Sofern Kärcher die Verbindungsprobleme nicht zu vertreten hat, gilt dies nicht als fehlende
          Verfügbarkeit der Leistungen nach diesem Vertrag. Solche Verbindungsprobleme, die Kärcher nicht zu vertreten
          hat,
          sind insbesondere atmosphärischen Störungen, topographischen Faktoren oder Hindernisse wie Brücken, Tunnel,
          Gebäude.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">5.</div>
        <div class="license-agreement-content__text">Recht zur Nutzung der Softwareanwendung</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">5.1</div>
        <div class="license-agreement-content__text">Kärcher räumt hiermit dem Kunden das nicht‐ausschließliche, nicht
          übertragbare und nicht unterlizenzierbare Recht ein, die Softwareanwendung während der Vertragslaufzeit nach
          den
          Regelungen dieser Vertragsbedingungen zu nutzen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">5.2</div>
        <div class="license-agreement-content__text">Informationen zur Wiedergabe von „Near-real-time"-Daten und zu
          weiteren
          Zusatzfunktionen können der Leistungsbeschreibung für das Hardwaremodul entnommen werden oder werden auf
          Anfrage
          von Kärcher zur Verfügung gestellt.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">5.3</div>
        <div class="license-agreement-content__text">Der Kunde ist nicht berechtigt, während der Vertragslaufzeit
          Dritten
          die Inanspruchnahme der Software entgeltlich oder unentgeltlich zu gestatten. Dritter ist jedoch nicht, wer im
          Auftrag des Kunden oder mit Einräumung einer Zugriffsberechtigung durch den Kunden die Leistungen
          unentgeltlich
          und bestimmungsgemäß zur Flotten- und Maschinenverwaltung in Anspruch nimmt, wie beispielsweise administrative
          Nutzer und Mitarbeiter des Kunden. Eine Weitervermietung der Software wird dem Kunden somit ausdrücklich nicht
          gestattet.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">6.</div>
        <div class="license-agreement-content__text">Rechte an Maschinendaten und Portaldaten</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">6.1</div>
        <div class="license-agreement-content__text">Die Alfred Kärcher SE & Co. KG ("AKW") ist Eigentümerin der der
          durch
          das Hardware-Modul für Datenübertragung erhobenen und im Kundenportal verarbeiteten Daten (nachfolgend
          „Maschinendaten") und alleinige Inhaberin der Rechte an diesen Maschinendaten. Dies gilt unabhängig von den
          Eigentumsverhältnissen an der Maschine, deren Daten erhoben werden.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">6.2</div>
        <div class="license-agreement-content__text">Der Kunde räumt AKW darüber hinaus das nicht-ausschließliche,
          zeitlich
          und räumlich unbegrenzte und unwiderrufliche Recht ein, die vom Kunden eingetragenen Portaldaten in
          anonymisierter
          Form für interne Zwecke, insbesondere zur Marktforschung und Produktverbesserung, zu nutzen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">6.3</div>
        <div class="license-agreement-content__text">Sofern es sich bei den verarbeiteten Daten um Geschäftsgeheimnisse
          des
          Kunden handelt, räumt der Kunde AKW und Kärcher an der Nutzung dieser Daten ein dauerhaftes und
          unwiderrufliches,
          nicht‐ausschließliches, nicht übertragbares Recht ein, die Daten in anonymisierter Form für eigene
          Geschäftszwecke
          zu verwenden (insbesondere für die Analyse und Auswertung zur Verbesserung der Leistungen nach diesem Vertrag
          und
          die Verbesserung anderer Produkte von Kärcher). AKW und Kärcher werden diese Daten gemäß Ziffer 15 dieser
          Vertragsbedingungen streng vertraulich behandeln.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">7.</div>
        <div class="license-agreement-content__text">Pflichten des Kunden</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">7.1</div>
        <div class="license-agreement-content__text">Der Kunde hat Störungen und Fehler des Kundenportals binnen
          angemessener Frist nach Auftreten Kärcher über das vorgesehene Kontaktformular im Kundenportal oder per E-Mail
          über die im Kundenportal angegebene Service-E-Mail-Adresse zu melden.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">7.2</div>
        <div class="license-agreement-content__text">Der Kunde muss in seinen Kontoinformationen für das Kundenportal
          einen
          Ansprechpartner (Administrator) benennen, der Kärcher für sämtliche Anfragen, die die Leistungen nach dem
          Vertrag
          sowie den Vertrag selbst betreffen, zu üblichen Geschäftszeiten zur Verfügung steht. Soweit der
          Ansprechpartner
          nicht zur Verfügung steht, benennt der Kunde eine Vertretung. Der Ansprechpartner sowie die jeweilige
          Vertretung
          muss ermächtigt sein, Entscheidungen in Bezug auf die Administrierung und Nutzung des Kundenportals für den
          Kunden
          zu treffen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">7.3</div>
        <div class="license-agreement-content__text">Zugriffsberechtigungen zum Kundenportal dürfen vom Kunden nur für
          von
          ihm berechtigte Mitarbeiter und Vertreter erteilt werden. Der Kunde ist verpflichtet, Zugriffsberechtigungen
          geheim zu halten und Dritten gegenüber nicht zugänglich zu machen. Er hat seine Mitarbeiter und Vertreter
          entsprechend zu verpflichten. Der Kunde ist ferner verpflichtet, Kärcher unverzüglich über einen möglichen
          Missbrauch von Zugriffsberechtigungen und Konten und über sonstige Sicherheitsvorfälle im Zusammenhang mit dem
          Kundenportal zu informieren.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">8.</div>
        <div class="license-agreement-content__text">Vergütung</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">8.1</div>
        <div class="license-agreement-content__text">Die Nutzung des Equipment Management im Kundenportal ist für den
          Kunden
          kostenfrei.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">8.2</div>
        <div class="license-agreement-content__text">Die Nutzung der „Near-real-time"-Daten und der über das Equipment
          Management hinausgehenden Zusatzfunktionen im Kundenportal für die ersten fünf Jahre nach Überlassung des
          Hardwaremoduls ist mit der Vergütung für den Kauf bzw. die Nutzungsüberlassung des Hardware-Moduls für
          Datenübertragung abgegolten. Nach Ablauf der fünf Jahre kann die weitere Nutzung kostenpflichtig verlängert
          werden.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">9.</div>
        <div class="license-agreement-content__text">Leistungsänderung</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__text">Kärcher behält sich das Recht vor, die Leistungen nach dem Vertrag
          anzupassen, soweit dies technisch oder aufgrund des Wechsels eines Subunternehmers notwendig ist. Die
          Leistungsänderung darf nicht zu einer wesentlichen Änderung der Leistungen führen oder für den Kunden auf
          sonstige
          Weise unzumutbar sein. Eine Erweiterung des Leistungsumfangs seitens Kärcher ist jederzeit möglich. Kärcher
          behält
          es sich jedoch vor, einen erweiterten Leistungsumfang, wie z.B. neue Funktionen und Features im Kundenportal,
          nur
          gegen eine zusätzliche Vergütung dem Kunden zur Nutzung bereitzustellen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">10.</div>
        <div class="license-agreement-content__text">Subunternehmer</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__text">Kärcher setzt zur Erbringung der Leistungen Subunternehmer ein. Die
          Auswahl der Subunternehmer steht im freien Ermessen von Kärcher. Kärcher wird seine Subunternehmer jedoch
          sorgfältig und unter Berücksichtigung berechtigter Interessen des Kunden auswählen, soweit diese Kärcher
          bekannt
          sind. Das Recht zur Wahl von Subunternehmern beinhaltet auch das Recht, Subunternehmer während der
          Vertragslaufzeit auszutauschen, es sei denn, dies beeinträchtigt berechtigte Interessen des Kunden.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">11.</div>
        <div class="license-agreement-content__text">Sperrung des Kundenkontos bei Rechtsverletzungen</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__text">Kärcher ist zur sofortigen Sperrung des Kundenkontos im
          Kundenportal
          berechtigt, wenn der begründete Verdacht besteht, dass die Erhebung oder Nutzung der Daten rechtswidrig ist
          oder
          Rechte Dritter verletzt. Zur Verletzung von Rechten Dritter gehört insbesondere die nicht ordnungsgemäße
          Verarbeitung der Daten nach der DSGVO (siehe insoweit Ziff. 14). Ein begründeter Verdacht für eine
          Rechtswidrigkeit und/oder eine Rechtsverletzung liegt insbesondere dann vor, wenn Gerichte, Behörden und/oder
          sonstige Dritte Kärcher davon in Kenntnis setzen. Kärcher wird hierzu den Kunden unverzüglich, soweit
          rechtlich
          zulässig, informieren. Die Sperre wird aufgehoben, wenn der Verdacht entkräftet ist.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">12.</div>
        <div class="license-agreement-content__text">Haftung</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">12.1</div>
        <div class="license-agreement-content__text">Kärcher haftet unbeschränkt für Vorsatz und grobe Fahrlässigkeit.
          Für
          leichte Fahrlässigkeit haftet Kärcher nur, wenn eine der vertragswesentlichen Pflichten durch Kärcher, seine
          gesetzlichen Vertreter oder leitende Angestellte oder Erfüllungsgehilfen verletzt wurde. Dabei ist die Haftung
          von
          Kärcher der Höhe nach auf den im Zeitpunkt des Vertragsschlusses typischen, vorhersehbaren Schaden begrenzt.
          Vertragswesentliche Pflichten sind solche Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des
          Vertrages
          überhaupt erst ermöglichen und auf deren Erfüllung der Kunde vertrauen darf.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__text">Im Übrigen ist eine Haftung von Kärcher ausgeschlossen.</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">12.2</div>
        <div class="license-agreement-content__text">Jegliche gesetzlich zwingende Haftung bleibt von Ziffer 12.1
          unberührt.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">13.</div>
        <div class="license-agreement-content__text">Laufzeit und Kündigung</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">13.1</div>
        <div class="license-agreement-content__text">Der Vertrag tritt mit der Zustimmung zu diesen AVB durch den Kunden
          in
          Kraft und läuft auf unbestimmte Zeit.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">13.2</div>
        <div class="license-agreement-content__text">Jede Partei ist berechtigt, den Vertrag mit einer Frist von drei
          Monaten zum Monatsende zu kündigen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">13.3</div>
        <div class="license-agreement-content__text">Das Recht der Parteien zur fristlosen Kündigung des Vertrages aus
          wichtigem Grund bleibt unberührt.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__text">Kärcher steht insbesondere ein Kündigungsrecht aus wichtigem Grund
          zu,
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">13.3.1</div>
        <div class="license-agreement-content__text">wenn der Kunde gegen wesentliche Bestimmungen oder Bedingungen des
          Vertrages verstößt, vorausgesetzt, eine vom Kärcher zur Abhilfe gesetzte Frist ist erfolglos abgelaufen oder
          eine
          erforderliche Abmahnung ist erfolglos geblieben;
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">13.3.2</div>
        <div class="license-agreement-content__text">soweit Kärcher das Festhalten an dem Vertragsverhältnis aus
          sonstigen
          schwerwiegenden Gründen nicht zugemutet werden kann.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">14.</div>
        <div class="license-agreement-content__text">Datenschutz</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">14.1</div>
        <div class="license-agreement-content__text">Beide Parteien beachten die einschlägigen gesetzlichen Bestimmungen
          zum
          Schutz personenbezogener Daten, insbesondere die Bestimmungen der Datenschutzgrundverordnung (DSGVO).
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">14.2</div>
        <div class="license-agreement-content__text">Die Parteien sind sich bewusst, dass die Kombination aus
          Systemkennzahlen auf Basis der von Kärcher zur Verfügung gestellten Maschinendaten und aus zusätzlichen, vom
          Kunden gegebenenfalls in Kundenportal eingepflegten Informationen personenbezogene Daten des Kunden bzw.
          seiner
          Mitarbeiter oder des Bedienpersonals der Maschinen enthalten kann.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">14.3</div>
        <div class="license-agreement-content__text">Der Kunde garantiert, dass er zur rechtmäßigen Verarbeitung der
          Daten
          im Kundenportal, insbesondere möglicher personenbezogener Daten, berechtigt ist. Der Kunde garantiert, dass
          die
          jeweiligen Erlaubnistatbestände zur Verarbeitung von personenbezogenen Daten vorliegen und aufrecht erhalten
          werden.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">14.4</div>
        <div class="license-agreement-content__text">Insbesondere im Beschäftigungskontext ist es alleinige
          Verantwortung
          des Kunden, dass die Datenerhebung und Nutzung im Kundenportal den geltenden Datenschutzbestimmungen
          entspricht.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">14.5</div>
        <div class="license-agreement-content__text">Die personenbezogenen Daten im Kundenportal werden von Kärcher nur
          im
          Auftrag des Kunden gemäß der beigefügten Vereinbarung zur Auftragsverarbeitung verarbeitet.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.</div>
        <div class="license-agreement-content__text">Geheimhaltung</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.1</div>
        <div class="license-agreement-content__text">Die Parteien verpflichten sich zur strikten und unbedingten
          Geheimhaltung sämtlicher Tatsachen, Informationen und Unterlagen einschließlich Geschäftsgeheimnissen (in
          schriftlicher, elektronischer, verkörperter oder mündlicher Form), von denen sie im Zusammenhang mit dem
          Vertrag
          sowie dessen Durchführung Kenntnis erlangt haben und die als vertraulich gekennzeichnet sind oder deren
          Vertraulichkeit sich aus den Umständen ergibt (nachfolgend „Vertrauliche Informationen"). Zu den Vertraulichen
          Informationen zählen insbesondere das Bestehen dieses Vertrags und sein Inhalt, digital verkörperte
          Informationen
          (Daten), betriebliche Abläufe und Know-how der Parteien. Als Vertrauliche Informationen, die vom Kunden geheim
          zu
          halten sind, gelten auch die Maschinendaten, die nach Ziffer 6 dieser Vertragsbedingungen alleiniges Eigentum
          von
          AKW sind und an denen AKW und die verbundenen Unternehmen alleinige Inhaber der Rechte sind.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.2</div>
        <div class="license-agreement-content__text">Diese Verpflichtung entfällt für Tatsachen, Informationen und
          Unterlagen, die
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.2.1</div>
        <div class="license-agreement-content__text">den Parteien bei Abschluss des Vertrages nachweislich bereits
          bekannt
          waren oder danach von dritter Seite bekannt werden, ohne dass dadurch eine Vertraulichkeitsvereinbarung,
          gesetzliche Vorschriften oder behördliche Anordnungen verletzt werden;
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.2.2</div>
        <div class="license-agreement-content__text">die bei Abschluss des Vertrages öffentlich bekannt sind oder danach
          öffentlich bekannt gemacht werden, soweit dies nicht auf einer Verletzung des Vertrages beruht;
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.2.3</div>
        <div class="license-agreement-content__text">die aufgrund gesetzlicher Verpflichtungen oder auf Anordnung eines
          Gerichts oder einer Behörde offen gelegt werden müssen. Soweit zulässig und möglich, wird die zur Offenlegung
          verpflichtete Partei die jeweils andere Partei vorab unterrichten und ihr Gelegenheit geben, gegen die
          Offenlegung
          vorzugehen. Darüber hinaus wird die Partei im Zuge der Offenlegung kenntlich machen, dass es sich, sofern dies
          der
          Fall ist, um Geschäftsgeheimnisse handelt, und darauf hinwirken, dass von den Maßgaben des §§ 16 ff. GeschGehG
          Gebrauch gemacht wird;
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.2.4</div>
        <div class="license-agreement-content__text">von einem Mitarbeiter der Partei ohne Kenntnis und Verwendung
          Vertraulicher Informationen selbst entwickelt wurden.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.3</div>
        <div class="license-agreement-content__text">Die Parteien werden nur solchen Beratern Zugang zu Vertraulichen
          Informationen gewähren, die dem Berufsgeheimnis unterliegen oder denen zuvor den Geheimhaltungsverpflichtungen
          dieses Vertrages im Wesentlichen entsprechende Verpflichtungen auferlegt worden sind. Des Weiteren werden die
          Parteien nur denjenigen Mitarbeitern und Mitarbeitern verbundener Unternehmen i.S.v. §§ 15 ff. AktG die
          vertraulichen Informationen offen legen, die diese für die Durchführung des Vertrages kennen müssen und denen
          zuvor den Geheimhaltungsverpflichtungen dieses Vertrages im Wesentlichen entsprechende Verpflichtungen
          auferlegt
          worden sind, und diese Mitarbeiter auch für die Zeit nach ihrem Ausscheiden in arbeitsrechtlich zulässigem
          Umfang
          zur Geheimhaltung verpflichten.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.4</div>
        <div class="license-agreement-content__text">Die Parteien sind zur Einhaltung des aktuellsten Standes der
          Technik in
          Bezug auf Datensicherheit und den Schutz Vertraulicher Informationen verpflichtet und werden diese durch
          angemessene technische und organisatorische Maßnahmen schützen. Der Parteien werden sich gegenseitig auf
          bestehende und drohende Sicherheitslücken hinweisen. Sie werden bei der Auswahl und Bestellung ihrer
          Mitarbeiter
          darauf achten, dass die jeweils tätigen Personen ihrerseits den aktuellsten Stand der Datensicherheit beachten
          und
          in all ihren Aktivitäten der Geheimhaltungsbedürftigkeit der Vertraulichen Informationen Rechnung tragen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.5</div>
        <div class="license-agreement-content__text">Der Kunde verpflichtet sich zudem, Vertrauliche Informationen nicht
          zurückzuentwickeln (Reverse Engineering), zu dekompilieren, zu disassemblieren oder in sonstiger Weise auf
          deren
          Zusammensetzung und/oder Herstellung zu untersuchen, sofern dies nicht für die Erbringung
          vertragsgegenständlicher
          Leistungen erforderlich ist und Kärcher dem zuvor ausdrücklich schriftlich zugestimmt hat. "Reverse
          Enginereeing"
          sind dabei sämtliche Handlungen, einschließlich des Beobachtens, Testens, Untersuchens und des Rück- sowie
          ggf.
          erneuten Zusammenbaus, mit dem Ziel, an Vertrauliche Informationen zu gelangen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.6</div>
        <div class="license-agreement-content__text">Endet der Vertrag, gleichgültig aus welchem Grund, sind sämtliche
          von
          den Parteien erlangten Vertraulichen Informationen einschließlich sämtlicher angefertigter Kopien
          zurückzugeben
          oder auf Verlangen der anderen Partei zu löschen und/oder zu vernichten. Im Fall der Löschung und/oder der
          Vernichtung muss die Rekonstruktion der Vertraulichen Informationen ausgeschlossen sein. Die vollständige
          Rückgabe
          bzw. Löschung und/oder Vernichtung ist durch die jeweilige Partei auf Verlangen schriftlich zu bestätigen. Die
          vorstehenden Bestimmungen gelten nicht für den allgemeinen Schriftverkehr zwischen den Parteien oder für
          Vertrauliche Informationen, deren Aufbewahrung den Parteien durch gesetzliche Vorschriften oder behördliche
          Anordnungen vorgeschrieben sind.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.7</div>
        <div class="license-agreement-content__text">Von der Pflicht zur Herausgabe und Löschung sind Maschinendaten,
          die
          nach Ziffer 6 dieser Vertragsbedingungen alleiniges Eigentum von AKW sind und an denen AKW alleinige Inhaberin
          der
          Rechte ist, ausgeschlossen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.8</div>
        <div class="license-agreement-content__text">Die Geheimhaltungspflicht der Parteien besteht während der Dauer
          des
          Vertrages und für einen weiteren Zeitraum von fünf (5) Jahren ab Beendigung des Vertrages.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">15.9</div>
        <div class="license-agreement-content__text">Kärcher ist berechtigt, in Pressemitteilungen oder sonstigen
          Veröffentlichungen über die Geschäftsbeziehung der Parteien nach dem Vertrag zu berichten oder den Kunden,
          dessen
          Firmenlogo oder eingetragene Marken als Referenz zu verwenden.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">16.</div>
        <div class="license-agreement-content__text">Änderung der Allgemeinen Vertragsbedingungen</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">16.1</div>
        <div class="license-agreement-content__text">Diese AVB sind im Hinblick auf die zum Vertragsschluss bestehenden
          tatsächlichen und rechtlichen Bedingungen erstellt worden. Für den Fall, dass sich diese Bedingungen ändern,
          insbesondere wenn sich zwingende gesetzliche Bestimmungen oder höchstrichterliche Rechtsprechung (z.B. durch
          den
          Bundesgerichtshof) ändern oder erstmals auftreten, ist Kärcher berechtigt, diese Vertragsbedingungen
          entsprechend
          den geänderten Bedingungen anzupassen. Dies gilt auch wenn die Vertragsdurchführung nach den Regelungen dieser
          AVB
          durch die geänderten Umstände unzumutbar wird.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">16.2</div>
        <div class="license-agreement-content__text">Kärcher hat dem Kunden die geänderten Vertragsbedingungen unter
          Hervorhebung und soweit dies erforderlich ist, Erläuterung der geänderten AVB Regelungen mindestens sechs (6)
          Wochen vor Inkrafttreten der geänderten AVB in Textform mitzuteilen. Die Mitteilung erfolgt durch eine
          Bekanntmachung im Kundenportal oder per Mail.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">16.3</div>
        <div class="license-agreement-content__text">Der Kunde kann den geänderten AVB in Textform bis Inkrafttreten der
          geänderten AVB widersprechen oder den Vertrag fristlos kündigen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">16.4</div>
        <div class="license-agreement-content__text">Widerspricht oder kündigt der Kunde den geänderten AVB nicht
          innerhalb
          der Frist der Ziffer 16.2 dieser AVB, gelten die geänderten AVB nach Ablauf der Frist.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">16.5</div>
        <div class="license-agreement-content__text">Widerspricht der Kunde den geänderten AVB ist Kärcher berechtigt
          den
          Vertrag zum Zeitpunkt des angekündigten Inkrafttretens der geänderten AVB zu kündigen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">16.6</div>
        <div class="license-agreement-content__text">Kärcher wird mit der Mitteilung der geänderten AVB den Kunden auf
          sein
          Widerspruchs- und Kündigungsrecht sowie auf die Wirkung eines Ausbleibens von Widerspruch und Kündigung nach
          Fristablauf hinweisen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">16.7</div>
        <div class="license-agreement-content__text">Im Übrigen bleiben die Regelungen in Ziffer 13 dieser AVB von
          dieser
          Ziffer 16 unberührt.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">17.</div>
        <div class="license-agreement-content__text">Schlussbestimmungen</div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">17.1</div>
        <div class="license-agreement-content__text">Der Vertrag unterliegt dem Recht der Bundesrepublik Deutschland
          unter
          Ausschluss des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf vom
          11.04.1980 (CISG) sowie des Kollisionsrechts.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">17.2</div>
        <div class="license-agreement-content__text">Ausschließlicher Gerichtsstand für sämtliche Streitigkeiten aus und
          im
          Zusammenhang mit dem Vertrag ist - soweit gesetzlich zulässig - Stuttgart. Kärcher ist jedoch berechtigt,
          gerichtliche Maßnahmen auch bei den für den Geschäftssitz des Kunden zuständigen Gerichten einzuleiten.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">17.3</div>
        <div class="license-agreement-content__text">Für die vertragsgegenständlichen Leistungen gelten ausschließlich
          diese
          Vertragsbedingungen. Entgegenstehende oder hiervon abweichende Bedingungen des Kunden finden keine Anwendung,
          es
          sei denn, Kärcher stimmt ihrer Geltung ausdrücklich schriftlich zu. Die Regelung in Satz 1 gilt auch dann,
          wenn
          Kärcher in Kenntnis entgegenstehender oder abweichender Bedingungen des Kunden vertragsgegenständliche
          Leistungen
          vorbehaltlos erbringt.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">17.4</div>
        <div class="license-agreement-content__text">Änderungen und Ergänzungen des Vertrags, die nicht der Ziffer 16
          unterfallen, bedürfen der Schriftform oder mindestens der einfachen oder fortgeschrittenen elektronischen
          Signatur. Dies gilt auch für die Änderung dieser Schriftformklausel. Nebenabreden wurden nicht getroffen.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">17.5</div>
        <div class="license-agreement-content__text">Sämtliche Erklärungen, die aufgrund des Vertrages zu tätigen sind,
          bedürfen der Schriftform, soweit nicht vertraglich ausdrücklich etwas anderes vereinbart ist.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">17.6</div>
        <div class="license-agreement-content__text">Sollte eine Bestimmung des Vertrags unwirksam oder undurchführbar
          sein
          oder werden, so wird dadurch die Wirksamkeit der übrigen Bestimmungen des Vertrags nicht berührt. Die
          unwirksame
          oder undurchführbare Bestimmung ist durch eine rechtswirksame Regelung zu ersetzen oder zu ergänzen, die dem
          entspricht, was die Parteien gewollt haben bzw. gewollt hätten, wenn sie die Unwirksamkeit oder
          Undurchführbarkeit
          erkannt hätten. Gleiches gilt für etwaige Vertragslücken.
        </div>
      </div>
      <div class="license-agreement-content__paragraph">
        <div class="license-agreement-content__index">17.7</div>
        <div class="license-agreement-content__text">Kärcher ist berechtigt, den Vertrag oder einzelne Rechte oder
          Verpflichtungen aus diesem Vertrag an mit Kärcher im Sinne von § 15 ff. AktG verbundene Unternehmen zu
          übertragen.
          In allen anderen Fällen bedarf eine Übertragung der schriftlichen Zustimmung der jeweils anderen Partei.
        </div>
      </div>
    </div>
  `,
};
