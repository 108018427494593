import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DownloadHeader } from '../../../../components/DownloadHeader/DownloadHeader';
import { selectUserCountryCode } from '../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Translations } from '../../../../cross-cutting-concerns/translations/Translations';
import { TermsConditionsUtils } from '../../utils/TermsConditionsUtils';
import { LicenseAgreementContent } from '../LicenseAgreementContent/LicenseAgreementContent';
import { StyledLicenseAgreement } from './LicenseAgreement.styles';

export const LicenseAgreement = (): JSX.Element => {
  const { t } = useTranslation();
  const userCountryCode = useSelector(selectUserCountryCode);
  const country = Translations.getCountryByCountryCode(userCountryCode);

  const filePath = TermsConditionsUtils.getLicenseAgreementFilePathByCountry(country);

  return (
    <StyledLicenseAgreement>
      <DownloadHeader title={t('common.licenseAgreement')} href={filePath} />
      <div className="license-agreement__content">
        <LicenseAgreementContent />
      </div>
    </StyledLicenseAgreement>
  );
};
