import produce from 'immer';
import { AnyAction } from 'redux';
import { Nullable } from '../../../../lib/types/Nullable';
import { TermsConditionsActions } from './termsConditionsActions';
import { AcceptTermsAndCondition } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface TermsConditionsState {
  hasAgreedToCurrentTerms: Nullable<boolean>;
  termsConditionsForm: {
    versions: string[];
    isLoading: boolean;
  };
}

export const initialState: TermsConditionsState = {
  hasAgreedToCurrentTerms: null,
  termsConditionsForm: {
    versions: [],
    isLoading: false,
  },
};

export const termsConditionsReducer = (state = initialState, action: AnyAction): TermsConditionsState =>
  produce(state, draft => {
    switch (action.type) {
      case TermsConditionsActions.ACCEPT_TERMS_CONDITIONS_REQUEST: {
        draft.termsConditionsForm.isLoading = true;
        return draft;
      }
      case TermsConditionsActions.ACCEPT_TERMS_CONDITIONS_SUCCESS: {
        const {
          acceptTermsAndCondition: { data },
        } = action.payload;

        draft.termsConditionsForm.isLoading = false;
        draft.termsConditionsForm.versions = data.map((datum: AcceptTermsAndCondition) => datum.version);
        return draft;
      }
      case TermsConditionsActions.ACCEPT_TERMS_CONDITIONS_ERROR: {
        draft.termsConditionsForm.isLoading = false;
        return draft;
      }

      case TermsConditionsActions.SET_HAS_AGREED_TO_CURRENT_TERMS: {
        draft.hasAgreedToCurrentTerms = action.payload;
        return draft;
      }

      case TermsConditionsActions.RESET_STATE: {
        return initialState;
      }

      default:
        return draft;
    }
  });
