import { Country } from '../../../cross-cutting-concerns/translations/interfaces/Translations.types';

export class TermsConditionsUtils {
  public static getContractObligationFilePathByCountry = (country: Country): string =>
    TermsConditionsUtils.CONTRACT_OBLIGATION_FILE_PATH_BY_COUNTRY_MAP[country];

  public static getLicenseAgreementFilePathByCountry = (country: Country): string =>
    TermsConditionsUtils.LICENSE_AGREEMENT_FILE_PATH_BY_COUNTRY_MAP[country];

  private static CONTRACT_OBLIGATION_FILE_PATH_BY_COUNTRY_MAP: Record<Country, string> = {
    [Country.AUSTRALIA]: '/assets/contract-obligations/EM_Contract_AVB_Australia.pdf',
    [Country.AUSTRIA]: '/assets/contract-obligations/EM_Kundenvertrag_AVB_Austria.pdf',
    [Country.BELGIUM]: '/assets/contract-obligations/EM_Contract_AVB_Belgium.pdf',
    [Country.CZECH_REPUBLIC]: '/assets/contract-obligations/EM_Contract_AVB_Czech-Republic.pdf',
    [Country.DENMARK]: '/assets/contract-obligations/EM_Contract_AVB_Denmark.pdf',
    [Country.FRANCE]: '/assets/contract-obligations/EM_Contract_AVB_France.pdf',
    [Country.GERMANY]: '/assets/contract-obligations/EM_Kundenvertrag_AVB_Germany.pdf',
    [Country.ITALY]: '/assets/contract-obligations/EM_Contract_AVB_Italy.pdf',
    [Country.NETHERLANDS]: '/assets/contract-obligations/EM_Contract_AVB_Netherlands.pdf',
    [Country.NORWAY]: '/assets/contract-obligations/EM_Contract_AVB_Norway.pdf',
    [Country.SWEDEN]: '/assets/contract-obligations/EM_Contract_AVB_Sweden.pdf',
    [Country.SWITZERLAND]: '/assets/contract-obligations/EM_Kundenvertrag_AVB_Switzerland.pdf',
    [Country.UNITED_KINGDOM]: '/assets/contract-obligations/EM_Contract_AVB_United Kingdom.pdf',
    [Country.USA]: '/assets/contract-obligations/EM_Contract_AVB_USA.pdf',
    [Country.SPAIN]: '',
    [Country.JAPAN]: '',
    [Country.PORTUGAL]: '',
    [Country.KOREA]: '',
  };

  private static LICENSE_AGREEMENT_FILE_PATH_BY_COUNTRY_MAP: Record<Country, string> = {
    [Country.AUSTRALIA]: '',
    [Country.AUSTRIA]: '',
    [Country.BELGIUM]: '',
    [Country.CZECH_REPUBLIC]: '',
    [Country.DENMARK]: '',
    [Country.FRANCE]: '',
    [Country.GERMANY]: '',
    [Country.ITALY]: '',
    [Country.NETHERLANDS]: '',
    [Country.NORWAY]: '',
    [Country.SWEDEN]: '',
    [Country.SWITZERLAND]: '',
    [Country.UNITED_KINGDOM]: '',
    [Country.USA]: '',
    [Country.SPAIN]: '',
    [Country.JAPAN]: '',
    [Country.PORTUGAL]: '',
    [Country.KOREA]: '',
  };
}
