import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RoutePaths } from '../../../../../config/route-paths';
import { LoadingPage } from '../../../../../lib/components/LoadingPage/LoadingPage';
import {
  selectAuthenticationTokens,
  selectIsLoggedIn,
} from '../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { TermsConditionsActions } from '../../state/termsConditionsActions';
import { selectHasAgreedToCurrentTerms } from '../../state/termsConditionsSelectors';
import { useDependencies } from 'app/cross-cutting-concerns/dependency-injection/hooks/useDependencies';

export interface TermsConditionsGuardProps {
  children?: React.ReactNode;
}

export const TermsConditionsGuard: React.FC<TermsConditionsGuardProps> = ({ children }) => {
  const dispatch = useDispatch();
  const tokens = useSelector(selectAuthenticationTokens);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { termsConditionsService } = useDependencies();
  const hasAgreedToCurrentTerms = useSelector(selectHasAgreedToCurrentTerms);

  useEffect(() => {
    const getTermAgreementStatusAsync = async (): Promise<void> => {
      const termAgreementStatus = await termsConditionsService.hasAgreedToCurrentTerms();

      dispatch(TermsConditionsActions.setHasAgreedToCurrentTerms(termAgreementStatus));
    };
    if (isLoggedIn) {
      getTermAgreementStatusAsync();
    }
  }, [isLoggedIn, tokens, termsConditionsService, dispatch]);

  if (hasAgreedToCurrentTerms === null) {
    return <LoadingPage />;
  }

  if (hasAgreedToCurrentTerms === false) {
    return <Navigate to={RoutePaths.TERMS_CONDITIONS} />;
  }

  return <>{children}</>;
};
