import { Action } from 'redux';

export type IShowContractObligationModalAction = Action<
  typeof TermsConditionsModalsActions.SHOW_CONTRACT_OBLIGATION_MODAL
>;
export type IHideContractObligationModalAction = Action<
  typeof TermsConditionsModalsActions.HIDE_CONTRACT_OBLIGATION_MODAL
>;

export type IShowLicenseAgreementModalAction = Action<typeof TermsConditionsModalsActions.SHOW_LICENSE_AGREEMENT_MODAL>;
export type IHideLicenseAgreementModalAction = Action<typeof TermsConditionsModalsActions.HIDE_LICENSE_AGREEMENT_MODAL>;

export class TermsConditionsModalsActions {
  public static SHOW_CONTRACT_OBLIGATION_MODAL = 'TERMS_CONDITIONS_MODALS::SHOW_CONTRACT_OBLIGATION_MODAL';
  public static HIDE_CONTRACT_OBLIGATION_MODAL = 'TERMS_CONDITIONS_MODALS::HIDE_CONTRACT_OBLIGATION_MODAL';
  public static SHOW_LICENSE_AGREEMENT_MODAL = 'TERMS_CONDITIONS_MODALS::SHOW_LICENSE_AGREEMENT_MODAL';
  public static HIDE_LICENSE_AGREEMENT_MODAL = 'TERMS_CONDITIONS_MODALS::HIDE_LICENSE_AGREEMENT_MODAL';

  public static showContractObligationModal(): IShowContractObligationModalAction {
    return {
      type: TermsConditionsModalsActions.SHOW_CONTRACT_OBLIGATION_MODAL,
    };
  }

  public static hideContractObligationModal(): IHideContractObligationModalAction {
    return {
      type: TermsConditionsModalsActions.HIDE_CONTRACT_OBLIGATION_MODAL,
    };
  }

  public static showLicenseAgreementModal(): IShowLicenseAgreementModalAction {
    return {
      type: TermsConditionsModalsActions.SHOW_LICENSE_AGREEMENT_MODAL,
    };
  }

  public static hideLicenseAgreementModal(): IHideLicenseAgreementModalAction {
    return {
      type: TermsConditionsModalsActions.HIDE_LICENSE_AGREEMENT_MODAL,
    };
  }
}
