import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LegacyPrimaryButton } from '../../../../../../lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { selectUserCountryCode } from '../../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Translations } from '../../../../../cross-cutting-concerns/translations/Translations';
import { TermsConditionsUtils } from '../../../utils/TermsConditionsUtils';
import * as termsConditionsModalsSelectors from '../../state/termsConditionsModalsSelectors';
import { TermsConditionsModalsActions } from '../../state/termsConditionsModalsActions';
import { StyledLicenseAgreementModal } from './LicenseAgreementModal.styles';
import { LegacySecondaryButton } from 'lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import { LicenseAgreementContent } from 'app/modules/terms-conditions/components/LicenseAgreementContent/LicenseAgreementContent';

export const LicenseAgreementModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(termsConditionsModalsSelectors.selectIsLicenseAgreementModalVisible);
  const userCountryCode = useSelector(selectUserCountryCode);
  const country = Translations.getCountryByCountryCode(userCountryCode);
  const filePath = TermsConditionsUtils.getLicenseAgreementFilePathByCountry(country);
  const isDownloadButtonVisible = filePath.length > 0;

  const handleCancel = useCallback(() => {
    dispatch(TermsConditionsModalsActions.hideLicenseAgreementModal());
  }, [dispatch]);

  const buttons = [
    <LegacySecondaryButton
      size="m"
      onClick={handleCancel}
      className="license-agreement-modal__cancel-button"
      key="cancel-button"
    >
      {t('common.back')}
    </LegacySecondaryButton>,
  ];

  if (isDownloadButtonVisible) {
    buttons.push(
      <LegacyPrimaryButton className="license-agreement-modal__download-button" size="m" href={filePath} download>
        {t('common.download')}
      </LegacyPrimaryButton>
    );
  }

  return (
    <StyledLicenseAgreementModal
      className="license-agreement-modal"
      title={t('common.licenseAgreement')}
      open={isVisible}
      footer={buttons}
    >
      <LicenseAgreementContent />
    </StyledLicenseAgreementModal>
  );
};
