import { produce } from 'immer';
import { AnyAction } from 'redux';
import { TermsConditionsModalsActions } from './termsConditionsModalsActions';

export interface TermsConditionsModalsState {
  contractObligation: {
    visible: boolean;
  };
  licenseAgreement: {
    visible: boolean;
  };
}

export const initialState: TermsConditionsModalsState = {
  contractObligation: {
    visible: false,
  },
  licenseAgreement: {
    visible: false,
  },
};

export const termsConditionsModalsReducer = (state = initialState, action: AnyAction): TermsConditionsModalsState =>
  produce(state, draft => {
    switch (action.type) {
      case TermsConditionsModalsActions.SHOW_CONTRACT_OBLIGATION_MODAL: {
        draft.contractObligation.visible = true;
        return draft;
      }

      case TermsConditionsModalsActions.HIDE_CONTRACT_OBLIGATION_MODAL: {
        draft.contractObligation.visible = false;
        return draft;
      }

      case TermsConditionsModalsActions.SHOW_LICENSE_AGREEMENT_MODAL: {
        draft.licenseAgreement.visible = true;
        return draft;
      }

      case TermsConditionsModalsActions.HIDE_LICENSE_AGREEMENT_MODAL: {
        draft.licenseAgreement.visible = false;
        return draft;
      }

      default:
        return draft;
    }
  });
