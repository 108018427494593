import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthenticationActions } from '../../../../cross-cutting-concerns/authentication/state/authenticationActions';
import { CURRENT_CONTRACT_OBLIGATION_VERSION, CURRENT_LICENSE_AGREEMENT_VERSION } from '../../constants';
import { TermsConditionsModalsActions } from '../../modals/state/termsConditionsModalsActions';
import { TermsConditionsActions } from '../../state/termsConditionsActions';
import * as termsConditionsSelections from '../../state/termsConditionsSelectors';
import { LicenseAgreementVersions } from '../../interfaces/TermsAndConditions.types';
import { StyledTermsAndConditions } from './TermsAndConditions.styles';
import { useDependencies } from 'app/cross-cutting-concerns/dependency-injection/hooks/useDependencies';
import { selectUserGroups } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { LegacyPrimaryButton } from 'lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { LegacySecondaryButton } from 'lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import { LegacyCheckbox } from 'lib/components/LegacyCheckbox/LegacyCheckbox';
import { LicenseAgreementModal } from 'app/modules/terms-conditions/modals/components/LicenseAgreementModal/LicenseAgreementModal';
import { ContractObligationModal } from 'app/modules/terms-conditions/modals/components/ContractObligationModal/ContractObligationModal';
import { RoutePaths } from 'config/route-paths';
import { isUserAdmin } from 'app/cross-cutting-concerns/authentication/utils/isUserAdmin';

export const TermsAndConditions = (): JSX.Element => {
  const { termsConditionsService } = useDependencies();
  const [hasAgreedToCurrentTerms, setHasAgreedToCurrentTerms] = useState<boolean | null>(null);

  const [formInstance] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = !!useSelector(termsConditionsSelections.selectIsLoading);
  const userGroups = useSelector(selectUserGroups);
  const isLicenseAgreementEnabled = CURRENT_LICENSE_AGREEMENT_VERSION !== LicenseAgreementVersions.V0;

  const onFinish = useCallback(
    () =>
      dispatch(
        TermsConditionsActions.acceptTermsConditionsRequest({
          input: {
            contractObligationVersion: CURRENT_CONTRACT_OBLIGATION_VERSION,
            licenseAgreementVersion: CURRENT_LICENSE_AGREEMENT_VERSION,
          },
        })
      ),
    [dispatch]
  );

  const handleOpenContractObligationModal = useCallback((): void => {
    dispatch(TermsConditionsModalsActions.showContractObligationModal());
  }, [dispatch]);

  const handleOpenLicenseAgreementModal = useCallback((): void => {
    dispatch(TermsConditionsModalsActions.showLicenseAgreementModal());
  }, [dispatch]);

  const handleBackToLogin = useCallback((): void => {
    dispatch(AuthenticationActions.signOut());
  }, [dispatch]);

  useEffect(() => {
    const getTermAgreementStatusAsync = async (): Promise<void> => {
      const termAgreementStatus = await termsConditionsService.hasAgreedToCurrentTerms();

      setHasAgreedToCurrentTerms(termAgreementStatus);
    };
    getTermAgreementStatusAsync();
  }, [termsConditionsService]);

  useEffect(() => {
    formInstance.resetFields();
  }, [formInstance]);

  if (hasAgreedToCurrentTerms) {
    return <Navigate to={RoutePaths.INIT} />;
  }

  return (
    <StyledTermsAndConditions className="terms-conditions">
      {isUserAdmin(userGroups) ? (
        <Form
          form={formInstance}
          name="terms-and-conditions"
          autoComplete="off"
          onFinish={onFinish}
          className="terms-conditions-form"
        >
          <h2 className="terms-conditions-form__title">{t('common.termsAndConditions')}</h2>
          <p className="terms-conditions-form__description">{t('termsAndConditions.form.description')}</p>
          <Form.Item
            name="contractObligation"
            valuePropName="checked"
            rules={[
              {
                // required for checkbox https://github.com/ant-design/ant-design/issues/8261#issuecomment-949036076
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error(t('termsAndConditions.form.errors.requiredContractObligation'))),
              },
            ]}
          >
            <LegacyCheckbox className="terms-conditions-form__checkbox">
              <span className="terms-conditions-form__required-mark">&#42;</span>
              <Trans i18nKey={'termsAndConditions.form.agreeWithContractObligation'}>
                <Button
                  type="link"
                  onClick={handleOpenContractObligationModal}
                  className="terms-conditions-form__button-link"
                />
              </Trans>
            </LegacyCheckbox>
          </Form.Item>
          {isLicenseAgreementEnabled && (
            <Form.Item
              name="licenseAgreement"
              valuePropName="checked"
              rules={[
                {
                  // required for checkbox https://github.com/ant-design/ant-design/issues/8261#issuecomment-949036076
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error(t('termsAndConditions.form.errors.requiredLicenseAgreement'))),
                },
              ]}
            >
              <LegacyCheckbox className="terms-conditions-form__checkbox">
                <span className="terms-conditions-form__required-mark">&#42;</span>
                <Trans i18nKey={'termsAndConditions.form.agreeWithLicenseAgreement'}>
                  <Button
                    type="link"
                    onClick={handleOpenLicenseAgreementModal}
                    className="terms-conditions-form__button-link"
                  />
                </Trans>
              </LegacyCheckbox>
            </Form.Item>
          )}
          <Form.Item className="terms-conditions-form__action">
            <LegacySecondaryButton size="m" onClick={handleBackToLogin}>
              {t('termsAndConditions.form.backToLogin')}
            </LegacySecondaryButton>
            <LegacyPrimaryButton size="m" type="primary" htmlType="submit" loading={isLoading}>
              {t('termsAndConditions.form.accept')}
            </LegacyPrimaryButton>
          </Form.Item>
          <LicenseAgreementModal />
          <ContractObligationModal />
        </Form>
      ) : (
        <div className="terms-conditions-no-access-content">
          <p>{t('termsAndConditions.noAccess.0')}</p>
          <p>{t('termsAndConditions.noAccess.1')}</p>
          <LegacySecondaryButton size="m" onClick={handleBackToLogin}>
            {t('termsAndConditions.form.backToLogin')}
          </LegacySecondaryButton>
        </div>
      )}
    </StyledTermsAndConditions>
  );
};
