import { Action } from 'redux';
import { TermsConditionsAcceptResponse, TermsConditionsClientAcceptOptions } from '../TermsConditionsClient';
import {
  IErrorAction,
  IActionCreatorErrorOptions,
} from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Optional } from 'lib/types/Optional';

export type IAcceptTermsConditionsRequestOptions = TermsConditionsClientAcceptOptions;
export type IAcceptTermsConditionsRequestAction = Action<
  typeof TermsConditionsActions.ACCEPT_TERMS_CONDITIONS_REQUEST
> & {
  payload: TermsConditionsClientAcceptOptions;
};

export type IAcceptTermsConditionsSuccessOptions = Optional<TermsConditionsAcceptResponse>;
export type IAcceptTermsConditionsSuccessAction = Action<
  typeof TermsConditionsActions.ACCEPT_TERMS_CONDITIONS_REQUEST
> & {
  payload: Optional<TermsConditionsAcceptResponse>;
};

export type IAcceptTermsConditionsErrorAction = IErrorAction<
  typeof TermsConditionsActions.ACCEPT_TERMS_CONDITIONS_ERROR
>;

export type ISetHasAgreedToCurrentTermsOptions = boolean;
export type ISetHasAgreedToCurrentTermsAction = Action<
  typeof TermsConditionsActions.SET_HAS_AGREED_TO_CURRENT_TERMS
> & {
  payload: ISetHasAgreedToCurrentTermsOptions;
};

export class TermsConditionsActions {
  public static ACCEPT_TERMS_CONDITIONS_REQUEST = 'TERMS_CONDITIONS::ACCEPT_TERMS_CONDITIONS_REQUEST';
  public static ACCEPT_TERMS_CONDITIONS_SUCCESS = 'TERMS_CONDITIONS::ACCEPT_TERMS_CONDITIONS_SUCCESS';
  public static ACCEPT_TERMS_CONDITIONS_ERROR = 'TERMS_CONDITIONS::ACCEPT_TERMS_CONDITIONS_ERROR';
  public static SET_HAS_AGREED_TO_CURRENT_TERMS = 'TERMS_CONDITIONS::SET_HAS_AGREED_TO_CURRENT_TERMS';

  public static RESET_STATE = 'TERMS_CONDITIONS::RESET_STATE';

  public static acceptTermsConditionsRequest(
    input: IAcceptTermsConditionsRequestOptions
  ): IAcceptTermsConditionsRequestAction {
    return {
      type: TermsConditionsActions.ACCEPT_TERMS_CONDITIONS_REQUEST,
      payload: input,
    };
  }

  public static acceptTermsConditionsSuccess(
    data: IAcceptTermsConditionsSuccessOptions
  ): IAcceptTermsConditionsSuccessAction {
    return {
      type: TermsConditionsActions.ACCEPT_TERMS_CONDITIONS_SUCCESS,
      payload: data,
    };
  }

  public static acceptTermsConditionsError({ error }: IActionCreatorErrorOptions): IAcceptTermsConditionsErrorAction {
    return {
      type: TermsConditionsActions.ACCEPT_TERMS_CONDITIONS_ERROR,
      error,
    };
  }

  public static setHasAgreedToCurrentTerms(
    termAgreementStatus: ISetHasAgreedToCurrentTermsOptions
  ): ISetHasAgreedToCurrentTermsAction {
    return {
      type: TermsConditionsActions.SET_HAS_AGREED_TO_CURRENT_TERMS,
      payload: termAgreementStatus,
    };
  }

  public static resetState(): Action<typeof TermsConditionsActions.RESET_STATE> {
    return {
      type: TermsConditionsActions.RESET_STATE,
    };
  }
}
