import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect, TakeEffect } from 'redux-saga/effects';
import { call, getContext, put, take, takeLatest } from 'typed-redux-saga';
import { AuthenticationActions } from '../../../cross-cutting-concerns/authentication/state/authenticationActions';
import { RoutePaths } from '../../../../config/route-paths';
import { TermsConditionsAcceptResponse } from '../TermsConditionsClient';
import {
  IAcceptTermsConditionsErrorAction,
  IAcceptTermsConditionsRequestAction,
  IAcceptTermsConditionsSuccessAction,
  TermsConditionsActions,
} from './termsConditionsActions';
import { Optional } from 'lib/types/Optional';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';

export function* acceptTermsConditionsSaga(
  action: IAcceptTermsConditionsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<TermsConditionsAcceptResponse>>
  | CallEffect<void>
  | TakeEffect
  | SelectEffect
  | PutEffect<IAcceptTermsConditionsSuccessAction>
  | PutEffect<IAcceptTermsConditionsErrorAction>,
  void,
  IDependencies
> {
  const { termsConditionsService, authenticationService, history } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(termsConditionsService.acceptTermsAndCondition, action.payload);

    yield* put(TermsConditionsActions.acceptTermsConditionsSuccess(response));

    yield* call(authenticationService.refreshSession);

    // Wait until tokens are updated to not be redirected from root page to terms page again
    yield* take(AuthenticationActions.TOKEN_REFRESHED);

    history.push(RoutePaths.ROOT);
  } catch (error) {
    console.error(error);
    yield* put(
      TermsConditionsActions.acceptTermsConditionsError({
        error,
      })
    );
  }
}

export function* termsConditionsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(TermsConditionsActions.ACCEPT_TERMS_CONDITIONS_REQUEST, acceptTermsConditionsSaga);
}
