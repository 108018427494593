import styled from 'styled-components';

export const StyledTermsAndConditions = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
  ${(props): string => props.theme.fontStyles.p1.news};

  .terms-conditions-form {
    width: 600px;
    margin: 0 auto;
    padding: 16px;
  }

  .terms-conditions-form__title {
    text-transform: uppercase;
    padding-bottom: 32px;
    ${(props): string => props.theme.fontStyles.heading1};
  }

  .terms-conditions-form__action {
    margin-top: 32px;

    span {
      text-transform: uppercase;
    }

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: space-between;
    }
  }

  .terms-conditions-form__required-mark {
    color: red;
    padding-right: 6px;
  }

  .terms-conditions-form__button-link {
    color: unset;
    padding: 0;
    height: unset;

    :hover {
      color: unset;
    }

    span {
      text-decoration: underline;
      ${(props): string => props.theme.fontStyles.p0};
    }
  }

  .terms-conditions-no-access-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    ${(props): string => props.theme.fontStyles.p1.medium}
    padding: 20px;
  }
`;
